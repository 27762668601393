import { Injectable, inject } from '@angular/core';
import { Route, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  private auth = inject(AuthService);
  private router = inject(Router);


  async canActivate(
    route: ActivatedRouteSnapshot): Promise<boolean>{
      const isLoggedIn = await this.auth.isLoggedIn();
      const isGoingToLoginPage = route.routeConfig.path === 'login';

      if (!isLoggedIn && isGoingToLoginPage) {
      return true;
    }

      if (isLoggedIn && !isGoingToLoginPage) {
      return true;
    }

      if (isLoggedIn && isGoingToLoginPage) {
      this.router.navigate(['/']);
      return false;
    }

      this.router.navigate(['/login']);
      return false;
  }


  async canLoad(
    route: Route): Promise<boolean> {

    const isLoggedIn = await this.auth.isLoggedIn();
    const isGoingToLoginPage = route.path === 'login';

    if (!isLoggedIn && isGoingToLoginPage) {
      return true;
    }

    if (isLoggedIn && !isGoingToLoginPage) {
      return true;
    }

    if (isLoggedIn && isGoingToLoginPage) {
      this.router.navigate(['/']);
      return false;
    }

    this.router.navigate(['/login']);
    return false;

  }
}
