import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, Roles } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard  {
  private auth = inject(AuthService);
  private router = inject(Router);


  async canActivate(): Promise<boolean> {
    const role = await this.auth.getRole();
    if (role === Roles.admin) {
      return true;
    }

    // this.router.navigate(['/login']);
    return false;

  }
}
