import { Injectable, inject } from '@angular/core';
import { TokenService } from '../token/token.service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders, HttpEvent } from '@angular/common/http';
import { AuthService } from './auth.service';
import { from, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  private tokenServ = inject(TokenService);
  private auth = inject(AuthService);


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleReq(req, next));
  }

  private async handleReq(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const token: any = await this.tokenServ.getToken();
    if (token) {
      const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
      // const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
      req = req.clone({ headers });
    } else {
      this.auth.logout();
    }

    return next.handle(req)
      .pipe(
        catchError(error => {
          // console.warn(error);

          // Checking if it is an Authentication Error (401)
          const messages = ['jwt expired', 'Session Expired'];
          if ((error.status === 401) && messages.includes(error?.error.message)) {
            this.auth.logout();
          }

          // Checking if user cannot access this resource
          if (error.status === 403) {
            console.warn('You can not access this resource');
          }

          // If it is not an authentication error, just throw it
          return throwError(error);
        })
      ).toPromise();

  }
}
