import { Routes } from '@angular/router';

import { AdminAuthGuard } from './services/auth/admin-auth.guard';
import { AuthGuard } from './services/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./components/app-shell/app-shell.component').then(m => m.AppShellComponent),
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/sales/new',
      },
      {
        path: 'sales',
        loadChildren: () => import('./pages/sales/sales.routes'),
        canActivate: [AuthGuard],
      },
      {
        path: 'customers',
        loadChildren: () => import('./pages/customers/customers.routes'),
        canActivate: [AuthGuard, AdminAuthGuard],
      },
      {
        path: 'users',
        loadChildren: () => import('./pages/users/users.routes'),
        canActivate: [AuthGuard, AdminAuthGuard],
      },
      {
        path: 'reports',
        loadChildren: () => import('./pages/reports/reports.routes'),
        canActivate: [AuthGuard, AdminAuthGuard],
      },

    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.routes'),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/login',
  }
];

export default routes;
